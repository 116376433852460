<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const isOpen = ref(false);
const sortArray = [
  {
    label: 'client.default',
    sort: {}
  },
  {
    label: 'client.highest_price',
    sort: { sort_by: 'start_price', sort_direction: 'desc' }
  },
  {
    label: 'client.lowest_price',
    sort: { sort_by: 'start_price', sort_direction: 'asc' }
  },
];

const handleSort = async ({ sort_by, sort_direction }) => {
  isOpen.value = false;

  const newQuery = { ...route.query };

  if (!sort_by) {
  
    delete newQuery.sort_by;
    delete newQuery.sort_direction;
  } else {
  
    newQuery.sort_by = sort_by;
    newQuery.sort_direction = sort_direction;
  }


  newQuery.page = 1;

  await router.push({ query: newQuery });
};
</script>

<template>
	<button @click="isOpen = true" class="flex items-center gap-1 text-sm">
		<i class="fa-solid fa-arrow-up-wide-short fa-xl"></i>
	</button>

	<w-slideover v-model="isOpen" :isBottom="true">

		<div class="sort p-4">
			<h1 class="mb-3 text-2xl">Sort</h1>
			<div class="mt-6 space-y-3">
				<div class="flex items-center border-b pb-2" v-for="(item, idx) in sortArray" :key="idx">
					<button @click="handleSort(item.sort)" class="w-full text-left">
						{{ $t(item.label) }}
					</button>
				</div>
			</div>
		</div>
	</w-slideover>
</template>
